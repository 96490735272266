export interface AssetTag {
  _id: string
  description: string
  color?: string
  organization?: string
  isAutoGenerated?: boolean
}

export const useAssetTagStore = defineStore(`asset-tag`, () => {
  /** @deprecated */
  const assetTags = ref<Array<AssetTag>>([])
  /** @deprecated */
  const tagAssetCount = ref<Array<CountAggregate>>([])

  const getAssetTags = async (pagination = false, organization?: string) => useCrudMethods<AssetTag>("/asset-tags", assetTags).readItems({ query: JSON.stringify({ organization }), pagination })
  const getAssetTagById = async (id: AssetTag["_id"]) => useCrudMethods<AssetTag>("/asset-tags", assetTags).readItemById(id)
  const updateAssetTag = async (id: AssetTag["_id"], item: Partial<AssetTag>) => useCrudMethods<AssetTag>("/asset-tags", assetTags).updateItem(id, item)
  const createAssetTag = async (item: Partial<AssetTag>) => useCrudMethods<AssetTag>("/asset-tags", assetTags).createItem(item)
  const deleteAssetTag = async (id: AssetTag["_id"]) => useCrudMethods<AssetTag>("/asset-tags", assetTags).deleteItem(id)

  const getTagAssetCount = async (organization?: string): Promise<Array<CountAggregate>> => {
    const { data } = await useSamApi<Array<CountAggregate>>(`/asset-tags/asset-count${organization ? `?organization=${organization}` : ""}`)
    tagAssetCount.value = data
    return data
  }

  return {
    assetTags,
    tagAssetCount,

    getAssetTags,
    getAssetTagById,
    updateAssetTag,
    createAssetTag,
    deleteAssetTag,

    getTagAssetCount,
  }
})
